"use client";

import { cn } from "@/lib/utils";
import { motion, useScroll, useSpring, useTransform } from "framer-motion";
import React, { useCallback, useEffect, useState } from "react";
import { useRef } from "react";
import { LeadForm } from "../lead/LeadForm";
import { Typography } from "@/components/ui/typography";
import { SectionLayout } from "../landing/SectionLayout";
import useShowOffer from "../landing/showOffer";
import useTrack from "@/lib/tracking";

export type NextTopScrollBarProps = {
  fromTop?: number;
  fromBottom?: number;
  onceFromTop?: boolean;
  onceFromBottom?: boolean;

  children: React.ReactNode;
  /**
   * Color for the TopLoader.
   * @default "#29d"
   */
  color?: string;
  /**
   * The initial position for the TopLoader in percentage, 0.08 is 8%.
   * @default 0.08
   */
  initialPosition?: number;
  /**
   * The increment delay speed in milliseconds.
   * @default 200
   */
  crawlSpeed?: number;
  /**
   * The height for the TopLoader in pixels (px).
   * @default 3
   */
  height?: number;
  /**
   * Auto increamenting behaviour for the TopLoader.
   * @default true
   */
  crawl?: boolean;
  /**
   * To show spinner or not.
   * @default true
   */
  showSpinner?: boolean;
  /**
   * Animation settings using easing (a CSS easing string).
   * @default "ease"
   */
  easing?: string;
  /**
   * Animation speed in ms for the TopLoader.
   * @default 200
   */
  speed?: number;
  /**
   * Defines a shadow for the TopLoader.
   * @default "0 0 10px ${color},0 0 5px ${color}"
   *
   * @ you can disable it by setting it to `false`
   */
  shadow?: string | false;
  /**
   * Timeout in ms before the TopLoader will appear.
   *
   * @default 0
   */
  delay?: number;
};

export const NextTopScrollBar = ({
  children,
  fromBottom = 92,
  fromTop = 8,
  onceFromTop = true,
  onceFromBottom = true,
}: NextTopScrollBarProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [showOffer, setShowOffer] = useShowOffer();
  const [hasShownFromTop, setHasShownFromTop] = useState(false);
  const [hasShownFromBottom, setHasShownFromBottom] = useState(false);
  const [scrollPercent, setScrollPercent] = useState(0);
  const [hasScrolledDown, setHasScrolledDown] = useState(false);
  const { track } = useTrack();

  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start start", "end end"],
  });
  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001,
  });

  const $scroll = useTransform(scrollYProgress, (value) => {
    return Math.ceil(value * 100) / 100;
  });

  useEffect(() => {
    const unsubscriber = $scroll.on("change", (v) => {
      const newValue = (Math.ceil(v * 100) / 100) * 100;
      setScrollPercent(newValue);
    });
    return unsubscriber;
  }, [$scroll]);

  useEffect(() => {
    if (scrollPercent > fromTop) {
      setHasScrolledDown(true);
    }
  }, [scrollPercent, fromTop, hasScrolledDown]);

  const shouldShowOffer = useCallback(() => {
    if (scrollPercent < fromTop && hasScrolledDown) {
      return !hasShownFromTop ? "top" : null;
    }
    if (scrollPercent > fromBottom) {
      return !hasShownFromBottom ? "bottom" : null;
    }
    if (showOffer === "top" && onceFromTop && !hasShownFromTop)
      setHasShownFromTop(true);
    if (showOffer === "bottom" && onceFromBottom && !hasShownFromBottom)
      setHasShownFromBottom(true);

    return null;
  }, [
    onceFromTop,
    onceFromBottom,
    showOffer,
    scrollPercent,
    fromTop,
    fromBottom,
    hasScrolledDown,
    hasShownFromTop,
    hasShownFromBottom,
  ]);

  useEffect(() => {
    const willShow = shouldShowOffer();
    if (willShow) track("Show - LeadForm", { from: willShow, popup: true });

    setShowOffer(willShow);
  }, [shouldShowOffer, track, setShowOffer]);

  const onCloseOffer = () => {
    track("Hide - LeadForm", { from: showOffer, popup: true });
    if (showOffer === "top") setHasShownFromTop(true);
    if (showOffer === "bottom") setHasShownFromBottom(true);
    setShowOffer(null);
  };

  return (
    <>
      <div
        key={"TopLoader"}
        style={{ zIndex: 1000 }}
        className="fixed left-0 top-0 h-[3.5px] w-full bg-background shadow-md"
      >
        <motion.div
          className="progress-bar fixed left-0 top-0 z-[1000] h-[3px] w-full rounded-3xl bg-foreground shadow-md"
          style={{ scaleX, transformOrigin: "0%" }}
        />
      </div>
      <div key={"MainContent"} ref={ref}>
        {children}

        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: showOffer ? 1 : 0, y: showOffer ? 0 : 50 }}
          transition={{ duration: 0.5 }}
          className={cn(
            "fixed max-md:min-h-screen inset-x-0 bg-foreground p-8 text-white overflow-hidden transition-all duration-500",
            {
              "top-0 z-[1001] delay-500": showOffer,
              "-top-full -z-10": !showOffer,
            },
          )}
        >
          <button
            onClick={onCloseOffer}
            className="absolute right-4 top-4 text-white transition-colors hover:text-gray-300"
            aria-label="Close offer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="size-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>

          <SectionLayout variant="invert" size="sm">
            <Typography variant="h3" className="text-center">
              {"Envie d'aller plus loin ?"}
            </Typography>
            <LeadForm arrowHidden submitButtonLabel="En savoir +" />
          </SectionLayout>
        </motion.div>
      </div>
    </>
  );
};
