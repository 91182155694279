"use client";
import { useEffect, useState } from "react";

export default function useShowOffer() {
  const [showOffer, setShowOffer] = useState<null | "top" | "bottom">(null);

  useEffect(() => {
    if (window.location.hash === "#hero-phone") {
      setShowOffer("top");
    }
  }, []);

  return [showOffer, setShowOffer] as const;
}
